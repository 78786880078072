function Trailer() {
  return (
    <div className="container mt-4">
      <div className="container-fluid">
        <div className="text-center mb-5">
          <img
            className="mb-5 img-fluid faw-orig"
            src="/assets/images/faw-orig-s.webp"
            alt="Fawesome Oirginals"
          />
          <h2>Greenwood Rising: The Rise of Black Wall Street</h2>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-lg-6 p-4 trailer-left">
          <img
            src="./assets/images/Greenwood-Rising_1920x1080.webp"
            className="heropic-de"
            alt="Greenwood Rising: The Rise of Black Wall Street"
          />
        </div>

        <div className="col-12 col-lg-6 p-4 trailer-right">
          <div>
            A must-watch historical drama that depicts the untold true story of
            O.W. Gurley and his wife, Emma, and their rise from humble
            beginnings. Together, they embark on a love-filled journey through
            one of the most adventurous chapters of Black history, overcoming
            racism and unjust laws to build the thriving community that became
            known as Black Wall Street.
          </div>
          <a
            href={getTrailerLaunchUrl()}
            className="btn btn-primary mt-4 gtm-lp gtm-trailer-watch-now"
          >
            Watch Now
          </a>
        </div>
      </div>
    </div>
  );

  function getTrailerLaunchUrl() {
    let item = {
      movie_title: "Greenwood Rising: The Rise of Black Wall Street",
      movie_description: "",
      movie_image:
        "https://futuretdy.s.llnwi.net/v4/files/styles/hd_movie_image/public/a8wn4hw/im/c8/b6/7043da45d7500b17b4e73e263b0a92c0.jpg",
      node_id: "10635196",
    };
    let urlFull = window.location.href;
    let url = urlFull;
    let qs = "";
    if (urlFull.indexOf("?") > -1) {
      url = urlFull.split("?")[0];
      qs = "?" + urlFull.split("?")[1];
    }
    let newUrl = url.replace(/\/#\/?$/, "");
    newUrl = window.location.protocol + "//" + window.location.hostname;
    let title = getCleanedTitle(item.movie_title);
    let nodeId = item.node_id;
    let base = "/";
    let itemType = "movies";
    if (item.itemType && item.itemType.toLowerCase() === "tv-shows") {
      itemType = "tv-shows";
    } else if (item.itemType && item.itemType.toLowerCase() === "category") {
      itemType = "category";
    } else if (item.itemType && item.itemType.toLowerCase() === "shows") {
      itemType = "shows";
    }
    //itemType = "tv-shows";
    let moviePath = `${itemType}/${nodeId}/${title}`;
    let moviePathWithSlash = base + moviePath;
    if (!newUrl.endsWith("/")) {
      moviePath = moviePathWithSlash;
    }
    let baseUrl = document.location.origin;
    //window.location.href = baseUrl + moviePath + qs;
    return baseUrl + moviePath + qs;
  }
  function getCleanedTitle(str) {
    str = str.toLowerCase().split(" ").join("-");
    /*// Replace all non-alphanumeric characters except hyphens with an empty string
		str = str.replace(/[^\w-]+/g, '-');
		// Replace consecutive hyphens with a single hyphen
		str = str.replace(/-{2,}/g, '-');
		return str;*/
    // Remove all non-alphanumeric characters except hyphens
    str = str.replace(/[^\w-]/g, "");
    // Remove consecutive hyphens
    str = str.replace(/-{2,}/g, "-");
    // Remove trailing hyphen (if present)
    str = str.replace(/-$/, "");
    return str;
  }
}
export default Trailer;
