import React, { useState, useEffect } from "react";
// import InputGroup from "react-bootstrap/InputGroup";
// import Form from "react-bootstrap/Form";
import { Nav, Navbar } from "react-bootstrap";

// import Dummy from "./Dummy";
let debounceTimer = null;
function HeroSection() {
  let heroImage = "./assets/images/heroImage-nov1.webp";
  let logoFawesome = "./assets/images/logo-fawesome.svg";
  let searchIcon = "./assets/images/search.svg";
  let browseIcon = "./assets/images/browse.svg";

  const [inputValue, setInputValue] = useState("");
  const [paddingTop, setPaddingTop] = useState(0);
  const [isOutOfViewport, setIsOutOfViewport] = useState(false);

  const calculatePadding = () => {
    // Select the elements by class name
    //const bgBodyTertiary = document.querySelector(".bg-body-tertiary");
    const herotitle = document.querySelector(".herotitle");
    const herotitleSub = document.querySelector(".herotitleSub");
    const ctaIntro = document.querySelector(".cta-intro");
    if (ctaIntro) {
      const rect = ctaIntro.getBoundingClientRect();
      const outOfViewport2 =
        rect.top >= window.innerHeight || // Below the viewport
        rect.bottom <= 0 || // Above the viewport
        rect.left >= window.innerWidth || // To the right of viewport
        rect.right <= 0; // To the left of viewport

      const outOfViewport =
        rect.top >= 0 && // Top is within viewport (not above)
        rect.bottom <= window.innerHeight - 200 && // Bottom is within viewport (not below)
        rect.left >= 0 && // Left is within viewport (not to the left)
        rect.right <= window.innerWidth; // Right is within viewport (not to the right)

      setIsOutOfViewport(outOfViewport);
      if (!outOfViewport) {
        // Calculate the total height of the selected elements
        const totalHeight =
          //(bgBodyTertiary?.offsetHeight || 0) +
          (herotitle?.offsetHeight || 0) +
          (herotitleSub?.offsetHeight || 0) +
          (ctaIntro?.offsetHeight || 0);

        // Get viewport height and calculate padding-top
        const viewportHeight = window.innerHeight;
        const calculatedPadding = viewportHeight - totalHeight - 100 - 75;
        // Set the padding-top dynamically
        setPaddingTop(calculatedPadding);
      }
    }
  };

  useEffect(() => {
    const navLinks = document.querySelectorAll(".bg-body-tertiary .nav-link");
    const searchInput = document.querySelectorAll(
      ".bg-body-tertiary #search-input"
    );
    navLinks.forEach((link) => {
      link.classList.add("gtm-lp", "gtm-nav-link");
    });
    searchInput.forEach((link) => {
      link.classList.add("gtm-lp", "gtm-search-text");
    });

    // Calculate padding on mount
    calculatePadding();

    // Add an event listener to recalculate on window resize
    window.addEventListener("resize", calculatePadding);

    // Cleanup the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", calculatePadding);
    };
  }, []);

  const menuInfo = [
    { name: "Home", link: `${document.location.origin}/home/` },
    {
      name: "Collections",
      link: `${document.location.origin}/categories/collections`,
    },
    { name: "Shows", link: `${document.location.origin}/categories/shows` },
    {
      name: "Best Movies",
      link: `${document.location.origin}/categories/best-movies`,
    },
    {
      name: "En Español",
      link: `${document.location.origin}/categories/en-español`,
    },
    { name: "Search", link: `${document.location.origin}/home/` },
  ];
  return (
    <>
      <Navbar className="bg-body-tertiary navbar-dark" expand="xl">
        <div className="container-fluid">
          <Navbar.Brand href={`${document.location.origin}/home/`}>
            {/* <a
              className="navbar-brand"
              rel="noopener noreferrer"
              onClick={handleClick}
            > */}
            <img
              src={logoFawesome}
              className="img-fluid fw-logo"
              alt="Fawesome"
              // style="opacity: 1;"
            />
            {/* </a> */}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            {/* <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#link">Collections</Nav.Link>
            <Nav.Link href="#link">Shows</Nav.Link>
            <Nav.Link href="#link">Best Movies</Nav.Link>
            <Nav.Link href="#link">Recommended</Nav.Link> */}
            {getNamedMenu()}
            {/* <Nav.Link>
              <div className="row ">
                <div className="input-group">
                  <img
                    src={searchIcon}
                    className="img-fluid fw-logo"
                    alt="Fawesome"
                    // style="opacity: 1;"
                  />
                  <input
                    className="form-control"
                    type="search"
                    value=""
                    id="search-input"
                    placeholder="Search"
                  />
                </div>
              </div>
            </Nav.Link> */}

            {/* <Nav.Link href="#link">About</Nav.Link> */}
          </Navbar.Collapse>
        </div>
      </Navbar>
      <div className="heroSection">
        <div className="fCorLeft"></div>

        <div
          className="herotitleCont"
          style={paddingTop > 0 ? { paddingTop: `${paddingTop}px` } : {}}
        >
          <h1 className="herotitle">Free Instant Streaming!</h1>
          <h3 className="herotitleSub">No registration. No subscription.</h3>
          <p className="cta-intro">
            <a
              className="btn btn-primary btn-browse-now gtm-lp gtm-browse-now"
              rel="noopener noreferrer"
              onClick={handleClick}
            >
              <img
                src={browseIcon}
                className="img-fluid"
                style={{ paddingRight: 15 }}
                alt="Fawesome"
                // style="opacity: 1;"
              />
              Watch Now
            </a>
          </p>
        </div>
      </div>

      <div className="heropicCont">
        <img src={heroImage} className="heropic" alt="fawesome" />
      </div>
    </>
  );

  function handleClick() {
    let urlFull = window.location.href;
    let url = urlFull;
    let qs = "";
    if (urlFull.indexOf("?") > -1) {
      url = urlFull.split("?")[0];
      qs = "?" + urlFull.split("?")[1];
    }
    var newUrl = url.replace(/\/#\/?$/, "");
    newUrl = window.location.protocol + "//" + window.location.hostname;
    var homePath = "/home/";
    if (newUrl.endsWith("/")) {
      homePath = "home/";
    }
    window.location.href = newUrl + homePath + qs;
  }

  function getNamedMenu() {
    return (
      <>
        {menuInfo.map((item, index) =>
          item.name == "Search" ? (
            // <Nav.Link key={index} className="cursor-auto">
            <div
              className="cursor-auto nav-link gtm-lp gtm-nav-link"
              key={index}
            >
              <div className="row ">
                <div className="input-group">
                  <img
                    src={searchIcon}
                    className="img-fluid fw-logo"
                    alt="Fawesome"
                    // style="opacity: 1;"
                  />
                  <input
                    className="form-control"
                    id="search-input"
                    placeholder="Search"
                    value={inputValue}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onKeyDown={(e) => {
                      handleKeyDown(e);
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            // </Nav.Link>
            <Nav.Link href={item.link} key={index}>
              {item.name}
            </Nav.Link>
          )
        )}
      </>
    );
  }

  function isValidInputForSearch(str) {
    // Regex allows input starting with letters or numbers, followed by any characters except %/\?
    return /^[a-zA-Z0-9](?!.*[%\/\\?]).*$/.test(str);
  }

  function handleChange(event) {
    var currentValue = event.target.value;
    if (!isValidInputForSearch(currentValue)) {
      event.target.value = currentValue.slice(0, -1); // Remove the last character if invalid
    }
    var searchText = event.target.value;
    setInputValue(searchText);
    console.log("value ", event.target.value);
    // Set debounce delay in milliseconds
    var debounceDelay = 2000;
    // Check if searchText has at least 2 letters
    if (searchText.length >= 2) {
      // Clear previous debounce timer
      clearTimeout(debounceTimer);
      // Set new debounce timer
      debounceTimer = setTimeout(function () {
        window.location.href = `${document.location.origin}/search/${searchText}`;
      }, debounceDelay);
    }
  }

  function handleKeyDown(event) {
    var searchText = event.target.value;
    clearTimeout(debounceTimer);
    if (event.key === "Enter") {
      if (searchText.length >= 2) {
        window.location.href = `${document.location.origin}/search/${searchText}`;
      }
    }
  }
}
export default HeroSection;
