import React, { useState } from "react";
import { Button } from "react-bootstrap";
function AppInstall() {
  const [state, setState] = useState(false);
  setTimeout(() => {
    setState(true);
  }, 5000);
  let flag = false;
  /*if (isAndroid() || isApple() || isWindows()) {
    flag = true;
  }*/
  if (displayAppInstallPopup()) {
    flag = true;
  }
  const [show, setShow] = useState(flag);
  //const handleClose2 = () => setShow(false);
  const handleClose = () => {
    //setGeneralCookie("appInstallClose", "yes", 1);
    setShow(false);
  };
  if (state && show) {
    return (
      <div className="container-fluid w-100 app-install-wrapper">
        <span className="app-install-close" onClick={handleClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-x-lg"
            viewBox="0 0 16 16"
          >
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
          </svg>
        </span>

        <div className="app-install">
          <span>Download the app for a better viewing experience.</span>
          <Button
            type="button"
            className="btn btn-primary btn-lg app-install-btn"
            data-dismiss="modal"
            onClick={() => handleInstall()}
          >
            Download App
          </Button>
        </div>
      </div>
    );
  } else {
    return "";
  }
  //if (!state || !show) {
  //  return "";
  //} else {
  /*return (
      <>
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header>
            <img src={"./assets/images/logo.svg"} alt="Fawesome" />
          </Modal.Header>
          <Modal.Body>Download Fawesome App and Watch Free.</Modal.Body>
          <Modal.Footer>
            <Button variant="light" className="btn-sm" onClick={handleClose}>
              Not Now
            </Button>
            <Button
              variant="secondary"
              className="btn-sm"
              onClick={() => handleInstall()}
            >
              Install
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );*/
  //}
}

function handleInstall() {
  let url = "";
  //setGeneralCookie("appInstallDL", "yes", 1);
  if (isAndroid()) {
    url =
      "https://play.google.com/store/apps/details?id=com.future.moviesByFawesomeAndroidTV&hl=en_US";
  } else if (isApple()) {
    url = "https://apps.apple.com/us/app/fawesome/id1063891742";
  } else if (isWindows()) {
    url =
      "https://www.microsoft.com/en-us/p/fawesome/9p55dw09xk2d?activetab=pivot:overviewtab";
  }
  window.location.replace(url);
}

function isAndroid() {
  let bAndroid = / Android/i.test(navigator.userAgent.toLowerCase());
  return bAndroid;
}

function isApple() {
  /*let isiDevice =
    (/iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) &&
    !window.MSStream;
  return isiDevice;*/
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  // Additional check for iPad on iOS 13 or later
  const iPadOS13 =
    navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1;
  return iOS || iPadOS13;
}

function isWindows() {
  let bWindows = false;
  try {
    if (navigator.appVersion.indexOf("Win") !== -1) {
      bWindows = true;
    } else if (navigator.userAgent.indexOf("Win") !== -1) {
      bWindows = true;
    } else {
      if ("Windows" in window) {
        if (
          window.Windows.System.Profile.AnalyticsInfo.deviceForm.indexOf(
            "Xbox"
          ) !== -1
        ) {
          bWindows = true;
        } else {
          bWindows = true;
        }
      }
    }
  } catch (e) {}

  return bWindows;
}
/*function setGeneralCookie(cname, cvalue, exdays) {
  cname = "ft_" + cname;
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + "; " + expires + "; path=/";
}
function removeCookie(cname, cvalue, exdays) {
  cname = "ft_" + cname;
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=; " + expires;
  document.cookie = "uid" + "=; " + expires;
  document.cookie = "sid" + "=; " + expires;
  document.cookie = cname + "=; " + expires + "; path=/";
  document.cookie = "uid" + "=; " + expires + "; path=/";
  document.cookie = "sid" + "=; " + expires + "; path=/";
}*/

function getCookie(cname) {
  cname = "ft_" + cname;
  var name = cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1);
    if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
  }

  return "";
}

function displayAppInstallPopup() {
  var appInstallDL = getCookie("appInstallDL");
  var appInstallClose = getCookie("appInstallClose");
  if (appInstallDL === "yes" || appInstallClose === "yes") {
    return false;
  }
  return true;
}

export default AppInstall;
